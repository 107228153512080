import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

  export let minAppConfig: PageType = {
    id: "amazon",
    logoUrl: '/default-black.png',
    logoHeight: 80,
    logoWidth: 80,
    buttonColor: '#3358d4',
    inputPlaceholder: Constants.ConstPlaceHolder,
    helpfulLabel: 'Helpful?',
    shopUrl: shopDomain + '/shopEverythingTest',
    // 'shopUrl': '/api/shop',
    storeId: 'claros',
    chatUrl: shopDomain + '/chat',
    //'chatUrl': 'api/chat',
    // 'recsUrl':  '/api/recs',
    // 'optionsUrl':  '/api/options',
    recsUrl: shopDomain + '/recTest',
    optionsUrl: shopDomain + '/options',
    affiliateLink: Constants.affiliateLink,
    productHeader: 'Products',
    productPlaceholder: 'Paste a URL for a product or type a search',
    recentProducts: [
      "cheap but cool earbuds",
      "a lightweight tent for backpacking in high-altitude environments",
      "a durable hiking boot for rocky terrains in the Andes",
      "a waterproof jacket for rainy weather hiking in the Pacific Northwest",
      "a portable water purifier for camping in areas with untreated water sources",
      "a thermal sleeping bag for cold weather camping in the Arctic",
      "a high-calorie protein bar for long hikes in energy-demanding trails",
      "a solar-powered portable charger for multi-day camping in areas with abundant sunlight",
      "a multi-tool kit for survival in the wilderness of the Amazon rainforest",
      "a compact first aid kit for outdoor emergencies in remote locations",
      "a UV protection sunglasses for desert hiking in the Sahara",
      "a breathable backpack for long distance trekking in humid climates",
      "massager for neck pain",
      "10-hour wear foundation",
      "anti-aging wellness supplement, especially for skin health",
      "non-slip car phone holder under $20",
      "modern couch for new apartment",
      "Ergonomic lumbar support for office chair",
      "Waterproof mascara for sensitive eyes",
      "Collagen peptides powder for joint and hair health",
      "Compact air purifier for allergy sufferers",
      "Stylish ergonomic desk chair for home office",
      "Durable travel suitcase with smart compartments",
      "Wireless earbuds with long battery life for running",
      "High SPF sunscreen for sensitive skin",
      "Adjustable standing desk converter under $100",
      "Smart thermostat for energy savings",
      "Plant-based protein powder for muscle gain",
      "Quiet hair dryer with ionic technology",
      "Portable blender for smoothies on-the-go"
    ]
    ,
    productButtonLabel: 'Set Product',
    feedbackPopupChatLength: 5,
    productCardHeader: "",
    productInstruction: 'What do you want to buy?',
    subheader: 'An AI expert to help you decide what to buy',
    showMoreProductsText: 'More Items',
    externalLinkLabel: 'See on Amazon',
    website: '/landingpage',
    linkSiteSubHeader: false,
    isMainPage: true,
    language: 'English',
    botName: ''
    //'productInstruction': 'Please select a product from the list below or type your own answer in the input field.',
};




